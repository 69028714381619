import React from 'react';
import { removeHost } from '../../utils/functions';
import ProductsListBox from './ProductsListbox';
import "./style.scss"


const ProductsList = ({ productBoxButton, products, columns, action, grid = true }) => {
  return (
    <section className="ProductsList">

      <div className={`ProductsList__wrapper ${grid ? (`grid grid--${columns}`) : (`flex flex--center flex--${columns}`)} `}>
        {products.map(product => {
          if (product.id && product.image) {
            return (
              <ProductsListBox
                key={'product_' + product.id}
                prodcutId={product.databaseId}
                action={action}
                img={product.image}
                name={product.name}
                link={removeHost(product.uri)}
                textButton={productBoxButton}
                tags={product.productTags.nodes}
                priceRegular={product.regularPrice}
                salePrice={product.salePrice}
              />
            )
          }
          else if (product.prodcutId && product.img) {
            return (
              <ProductsListBox
                key={'product_' + product.prodcutId}
                prodcutId={product.databaseId}
                action={action}
                img={product.img}
                name={product.name}
                link={removeHost(product.uri)}
                textButton={productBoxButton}
                tags={product.tags}
                priceRegular={product.priceRegular}
                salePrice={product.salePrice}
              />
            )
          }
          else {
            return '';
          }
        })}
      </div>
    </section>
  )
}
export default ProductsList
