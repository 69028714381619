import React, { useState } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getOgImage } from "../../utils/functions"
import Start from "../../components/quiz/start/Start"
import Question from "../../components/quiz/question/Question"
import QUIZ from "../../mutations/quiz"
import { useMutation } from "@apollo/client"
import End from "../../components/quiz/end/End"
// import "./style.scss"

const QuizPage = props => {
  const {
    pageContext: { title, seo, uri, acfQuiz, databaseId },
  } = props

  const {
    startButtonText,
    startDescriptionAfterButton,
    startDescriptionBeforeButton,
    startHeading,
    startBackgroundImage,
    questions,
    lastStepButton,
    nextStepButton,
    productHeading,
    productDescriptions,
    productButton,
    productAction,
  } = acfQuiz

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  const [step, setStep] = useState(1)
  const [answer, setAnswer] = useState([])
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [errorMessage, setErrorMessage] = useState("")

  const lastStepAction = () => {
    if (loading) {
      return
    }
    setStep(3)
    setLoading(true)
    quizAction()
  }

  const [quizAction] = useMutation(QUIZ, {
    variables: {
      input: {
        answarsString: answer.toString(),
        databaseID: databaseId + "",
      },
    },
    onCompleted: data => {
      setProducts(data.quiz.products)
      setLoading(false)
    },
    onError: error => {
      if (error) {
        setLoading(false)
        // console.log(error)
        setErrorMessage(error.graphQLErrors[0].message)

        // setRequestError(error.graphQLErrors[0].message);
      }
    },
  })

  return (
    <Layout lang={lang} page="quiz" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"quiz"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          <div className="">
            {step === 1 ? (
              <Start
                setStep={setStep}
                startButtonText={startButtonText}
                startDescriptionAfterButton={startDescriptionAfterButton}
                startDescriptionBeforeButton={startDescriptionBeforeButton}
                startHeading={startHeading}
                startBackgroundImage={startBackgroundImage}
              />
            ) : (
              ""
            )}
            {step === 2 ? (
              <Question
                lastStepAction={lastStepAction}
                questions={questions}
                nextStepButton={nextStepButton}
                lastStepButton={lastStepButton}
                setAnswer={setAnswer}
                answer={answer}
              />
            ) : (
              ""
            )}
            {step === 3 ? (
              <End
                heading={productHeading}
                description={productDescriptions}
                loading={loading}
                products={products}
                productButton={productButton}
                productAction={productAction}
              />
            ) : (
              ""
            )}
            {/* Start */}
            {/* Quiz */}
            {/* End */}
          </div>
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default QuizPage
