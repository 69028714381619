import { gql } from "@apollo/client";

/**
 * Register customer mutation query.
 */
const QUIZ = gql`
  mutation Quiz( $input: QuizInput! ) {
    quiz( input:$input ) {
      products{
        img
        name
        priceRegular
        prodcutId
        salePrice
        uri
        tags {
          name
          slug
        }
      }
    }
  }
`;

export default QUIZ;
